/* CSS VARS */
:root,
[data-theme='light'] {
	--base-background: #fff;
	--base-background-elevation-one: #fff;
	--base-background-elevation-two: #fff;
	--slightly-dark-backdrop: #eee;
	--basic-text-color: #222;
	--a-link-color: #006ec9;
	--hero-color: #9d4edd;
	--hero-color-hover: #8141b6;
	--home-accent-bg: #9d4edd89;
	--default-border-radius: 10px;
	--sub-text: #777;
	--green-success-color: #3fa13f;
	--green-success-hover-color: #358a35;
	--my-spins-list-border: 1px solid rgba(0, 0, 0, 0.16);
	--basic-button-color: #1c74cc;
	--basic-button-color-hover: #1e69b4;
	--icon-button-color: #2d93e7;
	--icon-button-hover: rgba(155, 155, 155, 0.2);
	--button-text-color: #fff;
	--basic-outline-color: rgba(0, 0, 0, 0.23);
	/* can be create or edit */
	--fileUploadUseCase: 'create';
	--armAnimationDuration: 1s;
	--armAnimationDurationOctoLoader: 600ms;
	--interactCursorColor: purple;
	--setting-slider-thumb-color: red;
	--neon-shadow-color: #db9eff;
	--neon-shadow-color-light: white;
	--draggable-image-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16), 0 1px 4px rgba(0, 0, 0, 0.23);
	--myspins-grid-colums: 0.5fr 0.75fr 0.25fr 0.5fr 1.2fr;
	--nav-link-hover-background: rgba(0, 0, 0, 0.1);
	--green-switch-color: #00c700;
	--switch-disabled-color: #f5f5f5;
	--switch-off-background-color: #e4e4e4;
	--switch-off-border-color: #6b6b6b;
	--danger-red: rgb(255, 71, 71);
	--danger-red-background: rgba(255, 0, 0, 0.1);
	--alert-orange: rgb(255, 132, 0);
	--alert-orange-background: rgba(255, 132, 0, 0.08);
}

[data-theme='dark'] {
	--base-background: #1e1e1e;
	--base-background-elevation-one: #282828;
	--base-background-elevation-two: #353535;
	--slightly-dark-backdrop: #1e1e1e;
	--home-accent-bg: rgba(157, 78, 221, 0.3);
	--a-link-color: #51b1ff;
	--basic-text-color: #eee;
	--basic-button-color: #1c74cc;
	--basic-button-color-hover: #1e69b4;
	--sub-text: #ddd;
	--my-spins-list-border: 1px solid rgba(199, 199, 199, 0.16);
	--hero-color: #ba66ff;
	--hero-color-hover: #a360da;
	--icon-button-color: #6bbcff;
	--basic-outline-color: rgba(255, 255, 255, 0.25);
	--button-text-color: #222;
	--nav-link-hover-background: rgba(255, 255, 255, 0.1);
	--green-switch-color: #51ff51;
	--switch-disabled-color: #797979;
	--switch-off-background-color: #353535;
	--switch-off-border-color: #acacac;
	--danger-red: rgb(255, 119, 119);
	--draggable-image-box-shadow: none;
	--alert-orange: rgb(255, 157, 53);
	--alert-orange-background: rgba(255, 157, 53, 0.08);
}

/* BASE TETST */
#root {
	height: 100%;
	width: 100%;
	display: flex;
	background: var(--base-background);
}

html,
body {
	background: var(--base-background);
	color: var(--basic-text-color);
	font-family: Helvetica, sans-serif;
	overflow-x: hidden;
}

a {
	color: var(--a-link-color);
	text-decoration: none;
}
a:hover:not(.linkItem a):not(.MuiMenuItem-root):not([type='button']) {
	text-decoration: underline;
	text-underline-offset: 0.12rem;
	cursor: pointer;
}

/* NAVIGATION */

.primaryNavBarContainer {
	position: fixed;
	z-index: 1203;
	height: 48px;
	width: 100%;
	top: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background: var(--base-background);
	border-bottom: 1px solid var(--basic-outline-color);
	user-select: none;
}

.primaryNavBar {
	width: 100%;
	padding: 0px 20px;
	height: 48px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.logoTitle {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 8px;
	font-size: 20px;
	font-weight: 600;
	color: var(--hero-color) !important;
	cursor: pointer;
	text-decoration: none !important;
}

.leftNavWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 20px;
}

.sliderIcon {
	transform: translate(0px, 0px);
	animation-name: sliderIconAnim;
	animation-iteration-count: 1;
	animation-timing-function: ease-out;
	animation-duration: 500ms;
}

@keyframes sliderIconAnim {
	0% {
		transform: translate(106px, -6px);
	}
	100% {
		transform: translate(0px, 0px);
	}
}

@media (max-width: 800px) {
	.collapsedPageList {
		display: flex;
		margin-left: 8px;
	}
	.fullPageList {
		display: none;
	}
	.accountSettingsButton {
		display: none !important;
	}

	.logoWrapper {
		height: 24px !important;
		width: 30px !important;
	}
	.primaryNavBar {
		padding: 0px 10px 0px 0px;
	}

	.leftNavWrapper {
		gap: 10px;
	}

	.crateButtonSeparate {
		display: flex !important;
	}
}

@media (max-width: 350px) {
	.createButtonText {
		display: none !important;
	}
	.createButtonIcon {
		display: flex !important;
	}
}
@media (min-width: 351px) {
	.createButtonIcon {
		display: none !important;
	}
	.createButtonText {
		display: flex !important;
	}
}
@media (min-width: 800px) {
	.createButtonIcon {
		display: none !important;
	}
	.createButtonText {
		display: none !important;
	}
}

@media (min-width: 800px) {
	.collapsedPageList {
		display: none;
	}
	.accountSettingsButton {
		display: flex;
	}
	.crateButtonSeparate {
		display: none !important;
	}
	.fullPageList {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	.primaryNavBar {
		padding: 0px 20px;
	}

	.leftNavWrapper {
		gap: 20px;
	}
}

.linkContainer {
	display: flex;
	flex-direction: row;
}

.linkItem a {
	user-select: none;
	padding: 8px 12px;
	border-radius: 100px;
}

.linkItem * {
	text-decoration: none;
	color: var(--basic-text-color);
}

.linkItem:hover a {
	filter: brightness(1.1);
	background-color: var(--icon-button-hover);
}

.titleWrapper {
	padding-left: 10px;
}

.navButtonWrapper {
	/* right: 0px; */
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-right: 10px;
	gap: 4px;
}

.MuiMenu-paper:not(.settingsMenu *) {
	background-color: var(--base-background-elevation-two) !important;
	color: var(--basic-text-color) !important;
}

.pagesMenuSmallScreen .MuiMenuItem-root {
	padding: 12px 48px 12px 24px;
	display: flex;
	flex-direction: row;
	gap: 6px;
}

.MuiMenuItem-root.MuiButtonBase-root:hover {
	background-color: var(--icon-button-hover) !important;
}

.pagesMenuSmallScreen .createButtonInMenu {
	color: var(--hero-color);
}

.createButtonFull {
	color: var(--hero-color);
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	gap: 2px;
	padding: 7px 10px !important;
}
.createButtonFull > span,
.createButtonFull > svg {
	color: var(--hero-color);
	fill: var(--hero-color);
	text-decoration: none;
}

@media (max-width: 600px) {
	.loginSignupText {
		display: none !important;
	}
	.loginSignupIcon {
		display: inline-flex !important;
	}
}
@media (min-width: 600px) {
	.loginSignupText {
		display: inline-flex !important;
	}
	.loginSignupIcon {
		display: none !important;
	}
}

.animationCanvas {
	touch-action: pan-y;
	display: block;
	user-select: none;
	-webkit-user-drag: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* ==================== CAPTURE ==================== */

@media (orientation: portrait) {
	.captureWrapper {
		flex-direction: column;
	}
	.cameraSection {
		height: calc(0.75 * 100%);
		width: 100%;
	}
	.cameraButtonSection {
		flex-direction: row;
		height: 25%;
	}
}

@media (orientation: landscape) {
	.captureWrapper {
		flex-direction: row;
	}
	.cameraSection {
		height: 100%;
		width: calc(0.75 * 100%);
	}
	.cameraButtonSection {
		flex-direction: column;
		height: 100%;
	}
}

/* ==================== CONTAINER ==================== */

.mainContentContainer {
	/* width: 100%;
  height: 98%; */
	flex: 1;
	margin-top: 48px;
	height: calc(100% - 48px);
	overflow: auto;
	/* padding: 20px; */
	/* height: 100vh;
     margin: 0 auto;
     overflow: auto; */
}

.embed_mainContainer {
	flex: 1;
	display: flex;
	margin-top: 0;
	height: 100%;
	width: 100%;
}

.contentContainer {
	width: 100%;
	height: 100%;
}

.randomTextContainer {
	word-wrap: normal;
	width: 100%;
	height: 100%;
	overflow: auto;
}

/* INTERACTION */

.individualImageBase {
	user-select: none;
	max-height: 100%;
	max-width: 100%;
	width: 100%;
	object-fit: contain;
}

.react-transform-wrapper {
	width: 100% !important;
	height: 100% !important;
}

.react-transform-component {
	width: 100% !important;
	height: 100% !important;

	align-content: center;
}

.react-transform-component {
	justify-content: center;
}

.embedContainer {
	flex: 1;
	display: flex;
	justify-content: center;
	height: 100%;
	width: 100%;
	background: red;
}

.animatedHand {
	display: flex;
	top: -0.3rem;
    justify-content: center;
    align-items: center;
	position: relative;
	transform: translateX(0%);
	animation-name: animateHandMove;
	animation-iteration-count: 4;
	animation-timing-function: ease-in-out;
	animation-duration: 12s;
	animation-delay: 0s;
}

@keyframes animateHandMove {
	0% {
		transform: scale(1)  translateX(0%) rotate(0deg);
	}
	10% {
		transform: scale(1) translateX(0%) rotate(0deg);
	}
	14% {
		transform: scale(0.9) translateX(0%) rotate(0deg);
	}
	18% {
		transform: scale(1) translateX(0%) rotate(0deg);
	}
	22% {
		transform: scale(0.9) translateX(0%) rotate(0deg);
	}
	26% {
		transform: scale(1) translateX(0%) rotate(0deg);
	}
    30% {
		transform: scale(1) translateX(0%) rotate(0deg);
	}
	34% {
		transform: translateX(0%) scale(0.9) rotate(0deg);
	}
	40% {
		transform: translateX(-80%) scale(0.9) rotate(-12deg);
	}
	52% {
		transform: translateX(80%) scale(0.9) rotate(12deg);
	}
	58% {
		transform: translateX(0%) scale(0.9) rotate(0deg);
	}
	62% {
		transform: translateX(0%) scale(1) rotate(0deg);
	}
	100% {
		transform: translateX(0%);
	}
}

.promptWrapper {
	position: absolute;
	z-index: 40;
	display: flex;
	flex-direction: column;
	gap: 6px;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	pointer-events: none;

	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 800ms;
}

.spinPad {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 100%;
	max-width: 240px;
	z-index: 20;
	flex: 4;
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	user-select: none;
	border-radius: var(--default-border-radius);
	margin: 2px;
	box-shadow: 0px 0px 12px -4px rgba(100, 100, 100, 0.5);
	opacity: 1;
	animation-name: fadeInOpacityAndTransform;
	animation-iteration-count: 1;
	animation-timing-function: ease-in-out;
	animation-duration: 500ms;
}



@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/* EDIT Page */
.editPageOvararch {
	height: 100%;
}

.basicFocus:focus-visible,
.basicFocus:focus {
	outline: 2px solid black !important;
	outline-offset: 0px !important;
}

.customSliderInputWrapper {
	height: 52px;
	width: 100%;
	position: relative;
}

.startingValueNotchClass {
	height: 12px;
	width: 3px;
	position: absolute;
	top: calc(50% - 2px);
	transform: translate(-50%, -50%);
	background: rgb(114, 114, 114);
	border-radius: 4px;
	z-index: 10;
}

.customSliderInputBack {
	height: 4px;
	width: 100%;
	position: absolute;
	top: calc(50% - 2px);
	transform: translateY(calc(-50%));
	background-color: rgba(69, 145, 221);
	opacity: 0.5;
	border-radius: 4px;
	z-index: 9;
}

.customSliderInput {
	width: 100%;
	padding: 14px 0px 18px 0px;
	margin: 0px;
	-webkit-appearance: none;
	height: 20px;
	position: absolute;

	background: transparent;
	outline: none;
	opacity: 1;
	-webkit-transition: 0.2s;
	transition: opacity 0.2s;
	z-index: 11;
}

.customSliderInput::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: attr(data-color-attribute, #222);
	cursor: pointer;
	z-index: 10;
}

.customSliderInput::-moz-range-thumb {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: attr(data-color-attribute, #222);
	cursor: pointer;
	z-index: 10;
}

.customSliderInput:not(.settingsSlider):focus-visible,
.customSettingsFocus:focus-visible {
	background: transparent !important;
	outline: 2px solid #1976d2;
	outline-offset: -1px;
}
.MuiSelect-icon {
	fill: var(--sub-text) !important;
}
.customNativeSelect:focus {
	background: grey !important;
	outline: 2px solid #1976d2;
	outline-offset: 2px;
}

.customNativeSelect::before,
.customNativeSelect::after {
	border: none !important;
}

.settingsMenuItem:focus-visible,
.settingsMenuItem:focus {
	background: grey !important;
	outline: 2px solid #1976d2;
	outline-offset: 2px;
}

.kbAssist {
	position: absolute;
	z-index: 100;
	top: 10px;
	left: 10px;
	display: flex;
	flex-direction: row;
	gap: 5px;
}
.kbAssist {
	opacity: 0;
	pointer-events: none;
}
.kbAssist:focus-within
  {
	opacity: 1;
	pointer-events: unset;

}
.kbAssist button:focus {
	outline-offset: 2px;
    outline: 2px solid #339af0;
}

.kbAssist > button {
	background: rgba(245, 245, 245, 0.6);
	border: 2px solid rgba(0, 0, 0, 0.8);
	border-radius: 5px;
	color: black !important;
	padding: 0.3rem 0.6rem;
	font-size: 1rem;
	font-weight: bold;
	text-shadow: #FFF 1px 0 8px;;

}

.viewerOverarch {
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.customNativeSelect > select {
	padding: 12px 8px;
}
.customNativeSelect:hover {
	background: var(--icon-button-hover) !important;
}

.settingsSlider:hover {
	cursor: pointer;
	/* background: rgba(0, 0, 0, .04) !important; */
}

.sliderSettingsWrapper:hover {
	/* cursor: pointer; */
	background: var(--icon-button-hover) !important;
}

.settingsSliderWrapper {
	padding: 12px;
}

.colorMenuDialog {
	z-index: 1500 !important;
}
.colorMenuDialog .MuiBackdrop-root {
	background-color: transparent !important;
}

.colorPickerInnerWrapper {
	display: flex;
	padding: 20px;
	flex-direction: column;
	gap: 8px;
	height: 100%;
	overflow: auto;
}

.colorPickerInnerWrapper > div {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.hexColorInput {
	width: 10ch !important;
	padding: 3px;
	border-radius: 4px;
	border: 1px solid grey;
	text-align: center;
}

.mantine-Button-filled:not(.__mantine-ref-loading):disabled {
	color: #000 !important;
	opacity: 0.5 !important;
}

.confirmColorButton {
	background-color: rgb(0, 170, 0) !important;
	color: white !important;
}
.confirmColorButton:hover {
	color: white !important;
	filter: brightness(0.85) !important;
}

.wrapperPreviewSettings_Edit {
	display: flex;
	flex-direction: row;
	height: 100%;
	flex-wrap: wrap;
}

.previewSection_Edit {
	flex: 2;
	min-width: 375px;
}

.settingsSection_Edit {
	flex: 2;
	min-width: 375px;
}

.editSettingsOverarch {
	display: flex;
	flex-direction: column;
	height: 100%;
	border-right: 1px solid var(--basic-outline-color);
}

.saveSettingsSectionWrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
	position: sticky;
	top: 0;
	z-index: 40;
	background: var(--base-background);
}

.savedSettingsDropdownLabel:not(.MuiInputLabel-shrink) {
	transform: translate(14px, 9px) scale(1) !important;
}

.savedSettingsManageIcon {
}
.savedSettingsManageIcon:hover {
	background-color: transparent !important;
}
.changeSettingsSectionWrapper {
	overflow: auto;
	height: 100%;
}

.changeSettingsSectionWrapperInner {
	width: calc(100% - 32px);
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 0px 16px 16px 16px;
}

.changeSettingsSectionGroup {
	border-radius: var(--default-border-radius);
	padding: 10px;
	background: rgba(231, 206, 255, 0.4);
	box-shadow: inset 0 0px 10px -8px rgba(100, 100, 100, 0.8);
}

.inputWrapperOverarch {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 20px;
}

.inputWrapper {
	position: relative;
	padding-top: 0.24em;
	padding-bottom: 0.16em;
}

.inputWrapperGroup {
	border: 1px solid var(--basic-outline-color);
	border-radius: var(--default-border-radius);
	margin: 16px;
}

#firstImageAccordionSummary > .MuiAccordionSummary-content {
	margin: 0px !important;
	font-size: 0.94rem !important;
	font-weight: 600;
	color: var(--basic-text-color) !important;
}

.MuiSwitch-switchBase.Mui-disabled {
	color: var(--switch-disabled-color) !important;
}

.basicSwitchOff {
	border: 1px solid var(--switch-off-background-color);
	background-color: var(--switch-off-background-color) !important;
}

.greenSwitch > span.MuiSwitch-switchBase.Mui-checked {
	color: var(--green-switch-color) !important;
}
.greenSwitch > span.MuiSwitch-track {
	background-color: var(--green-switch-color) !important;
}

.greySwitch > span.MuiSwitch-switchBase.Mui-checked {
	color: #6d6d6d !important;
}
.greySwitch > span.MuiSwitch-track.Mui-checked {
	background-color: #6d6d6d !important;
}

.customSwitchFormControl {
	margin: 0 !important;
	padding: 8px 6px 8px 16px !important;
	display: flex !important;
	flex-direction: row-reverse !important;
	justify-content: space-between;
}

.customSwitchFormControl > .MuiFormControlLabel-label {
	font-size: 0.94rem !important;
	color: var(--basic-text-color) !important;
}

.customSettingsSelect:not(.MuiInputLabel-shrink) {
	transform: translate(14px, 8px) scale(1) !important;
}

.MuiAccordionSummary-expandIconWrapper svg {
	color: var(--sub-text);
}

.MuiSvgIcon-root:not(.iconButtonLabeled .MuiSvgIcon-root):not(.settingsWrapperActions svg):not(.settingsMenu svg) {
	color: var(--sub-text);
}
.editQuickButtonWrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding-top: 10px;
}
.editAccordion {
	border-radius: var(--default-border-radius) !important;
	border: 1px solid var(--basic-outline-color) !important;
	box-shadow: none !important;
}

.editAccordion::before {
	content: none !important;
}

.editAccordion.Mui-expanded {
	padding-bottom: 10px;
	margin: 0px !important;
}

.MuiAccordionSummary-root.Mui-expanded {
	min-height: 32px !important;
}

.MuiAccordionSummary-content {
	font-size: 1.1rem;
	font-weight: 600;
	color: var(--lighter-text-color) !important;
	margin: 12px 0px !important;
}

.MuiAccordion-root {
	background-color: var(--base-background-elevation-one) !important;
	color: inherit !important;
}

.MuiAccordionDetails-root {
	padding: 0 !important;
}

.cursorSelectButton {
	width: 100%;
	background: unset;
	border: none;
	font-size: 0.94rem;
	color: var(--basic-text-color) !important;
	padding: 12px 16px 12px 16px;
}
.cursorSelectButton:hover {
	cursor: pointer;
}

.cursorOptionsMenuItem:hover,
.cursorOptionsMenuItem:focus {
	background: var(--icon-button-hover) !important;
}

@media (max-width: 750px) {
	.previewSection_Edit {
		height: 40%;
		order: 1;
	}
	.settingsSection_Edit {
		height: 60%;
		order: 2;
	}
	.saveSettingsSectionWrapper {
		padding: 4px 16px 4px 16px;
	}
}

.publicTitleTextField input {
	padding: 6px 14px;
}

.settingsSection_Edit {
	z-index: 1201;
}

@media (min-width: 750px) {
	.previewSection_Edit {
		height: 100%;
		order: 1;
	}
	.settingsSection_Edit {
		/* overrule settings menu z index */
		height: 100%;
		order: 2;
		max-width: 375px;
	}
	.saveSettingsSectionWrapper {
		padding: 20px 16px 10px 16px;
	}
}

.disabledOnEdit {
	pointer-events: none;
	cursor: default;
}

/* SETTINGSMENU */
.settingsWrapper {
	position: absolute;
	right: 0px;
	bottom: 0px;
	display: flex;
	flex-direction: row;
	z-index: 20;
	justify-content: flex-end;
}

.settingsWrapperActions {
	flex: 1;
	display: flex;
	justify-content: flex-end;
	flex-direction: row;
	gap: 4px;
    padding: 4px;
}

.Mui-focusVisible.disabledRipple:focus-visible {
	outline: inset 1px #000;
}

@media (max-width: 175px) {
	.settingsWrapper {
		flex-direction: column !important;
	}
}

/* BRANDING */
.brandingWrapper {
	position: absolute;
	left: 0px;
	bottom: 0px;
	padding: 4px 8px;
	display: flex;
	z-index: 21;
}

.brandingWrapper * {
	text-decoration: none;
	color: rgb(0, 0, 0);
	font-weight: 600;
	text-shadow: 1px 1px 4px rgb(255, 255, 255);
	opacity: 1;
}

/* MY SPINS */

.mySpinsListOverarch {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 0px 5px;
	margin: 0px 5px 20px 5px;
}
.mySpinsFilterOverarch {
	position: sticky;
	top: 0;
	background: var(--base-background);
	width: 100%;
	max-width: 1000px;
	z-index: 30;
	border-bottom: 1px solid var(--basic-outline-color);
	display: flex;
	gap: 10px;
	flex-direction: column;
	align-items: flex-end;
	padding-top: 10px;
}
.mySpinsFilterWrapper {
	width: 100%;
	z-index: 30;
	display: grid;
	gap: 10px;
	flex-direction: row;
	padding: 10px;
}

@media (max-width: 750px) {
	.mySpinsFilterWrapper {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, auto);
		max-width: calc(100% - 20px);
	}
	.filtersSearch {
		grid-column: 1 / 3;
		grid-row: 1 / 2;
	}
	.filtersSort {
		grid-column: 1 / 2;
		grid-row: 2 / 3;
		max-width: calc(50vw - 25px);
	}
	.filtersShow {
		grid-column: 2 / 3;
		grid-row: 2 / 3;
		max-width: calc(50vw - 25px);
	}
}
@media (min-width: 750px) {
	.mySpinsFilterWrapper {
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(1, auto);
		max-width: fit-content;
	}
}

.mySpinsFilter {
	position: sticky;
	top: 0;
	background: var(--base-background);
	width: 100%;
	max-width: 1000px;
	z-index: 30;
	border-bottom: 1px solid rgb(182, 182, 182);
	display: flex;
	gap: 10px;
	flex-direction: column;
}

.filterActionsWrapper {
	display: flex;
	gap: 10px;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	flex-wrap: wrap;
}

@media (max-width: 799px) {
	.filterActionsWrapper {
		display: flex;
		row-gap: 0px;
		column-gap: 10px;
		flex-direction: row;
		align-items: flex-end;
		justify-content: left;
		flex-wrap: wrap;
	}

	.filterWrapperAccordion {
		margin: 6px 0px !important;
	}

	.filterActionsWrapper .mySpinsFilterSelect {
		margin: 8px 0px 0px 0px !important;
	}

	.filterActionsWrapper .iconButtonLabeled {
		transform: translateY(6px);
	}
}
@media (min-width: 800px) {
	.filterActionsWrapper {
		display: flex;
		gap: 10px;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		flex-wrap: wrap;
	}
}

.filterWrapperAccordion {
	background: unset !important;
	box-shadow: unset !important;
}

.filterWrapperAccordion::before {
	content: none;
	display: none;
}

.mySpinsLabelBar {
	display: grid;
	grid-template-columns: var(--myspins-grid-colums);
	padding: 0px 20px 4px 20px;
	gap: 10px;

	width: calc(100% - 40px);
}

.mySpinsLabelBar > div {
	text-align: center;
	color: var(--sub-text);
	font-size: 0.9em;
}

.mySpinsListWrapper {
	display: grid;
	grid-auto-flow: row;
	gap: 10px;
	padding: 10px;
	width: 100%;
	max-width: 980px;
	opacity: 1;
	animation-name: mySpinsItemFadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 500ms;
}

@media (max-width: 599px) {
	.mySpinsListItem {
		display: grid;
		grid-template-columns: 0.5fr 1fr;
		grid-template-rows: repeat(4, auto);
		gap: 4px;
		padding: 5px;
	}

	.mySpinsListItem > div:nth-of-type(1) {
		grid-row-start: 1;
		grid-row-end: 4;
		grid-column-start: 1;
		grid-column-end: 2;
	}
	.mySpinsListItem > div:nth-of-type(2) {
		grid-row-start: 1;
		grid-row-end: 1;
		grid-column-start: 2;
		grid-column-end: 3;
	}
	.mySpinsListItem > div:nth-of-type(3) {
		grid-row-start: 3;
		grid-row-end: 3;
		grid-column-start: 2;
		grid-column-end: 3;
	}
	.mySpinsListItem > div:nth-of-type(4) {
		grid-row-start: 2;
		grid-row-end: 2;
		grid-column-start: 2;
		grid-column-end: 3;
	}
	.mySpinsListItem > div:nth-of-type(5) {
		grid-row-start: 4;
		grid-row-end: 4;

		grid-column-start: 1;
		grid-column-end: 3;
	}

	.mySpinsActionItems {
		padding: 0;
		justify-content: space-evenly !important;
	}

	.mySpinsCreated::before {
		content: 'Created:';
		padding-right: 5px;
	}

	.mySpinsLabelBar {
		display: none;
	}

	.publicIconLabeled {
		flex-direction: row !important;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 4px;
	}

	.publicIconLabeled > .MuiTypography-root {
		font-size: 1em;
		transform: none;
	}
	.iconButtonLabeled > .MuiTypography-root {
		line-height: unset !important;
	}
}

.mySpinsListItem {
	display: grid;
	column-gap: 8px;
}

@media (min-width: 600px) {
	.mySpinsListItem {
		display: grid;
		grid-template-columns: var(--myspins-grid-colums);
		gap: 10px;
	}

	.mySpinsActionItems {
		padding: 10px 0px;
	}
}

@media (min-width: 800px) {
	.mySpinsFilter {
		padding-top: 20px;
	}
}

.mySpinsActionItemButton {
	color: var(--icon-button-color) !important;
}

.mySpinsActionItemButton:hover {
	background-color: transparent !important;
}

.mySpinsCreated {
	display: flex;
	justify-content: center;
	align-items: center;
}

.mySpinsNickname {
	color: var(--basic-text-color);
	white-space: nowrap;
	text-overflow: ellipsis;
	display: block;
	overflow: hidden;
	max-width: 100%;
	text-align: center;
	margin: auto;
}

.mySpinsCreated {
	color: var(--sub-text);
}

.iconButtonLabeled {
	display: flex;
	flex-direction: column;
	margin: 0 !important;
	padding: 2px;
}

.iconButtonLabeled:hover {
	background: var(--icon-button-hover);

	border-radius: 6px;
	cursor: pointer;
}

.iconButtonLabeled span {
	user-select: none;
	font-size: 0.8em;
	transform: translateY(-6px);
	color: var(--icon-button-color);
}

.publicIconLabeled {
	display: flex;
	flex-direction: column;
	margin: 0 !important;
	cursor: default !important;
}

.publicIconLabeled:hover {
	cursor: default !important;
}

.publicIconLabeled span {
	user-select: none;
	font-size: 0.8em;
	transform: translateY(4px);
	color: var(--icon-button-color);
}

.publicIconLabeled.liveIcon span {
	color: var(--green-success-color);
}

.publicIconLabeled.hiddenIcon span {
	color: rgb(157, 157, 157);
}

@keyframes mySpinsItemFadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.mySpinsRefreshButton:not([disabled]) > svg {
	color: var(--icon-button-color);
}

.mySpinsListItem {
	padding: 10px;
	border-radius: 5px;
	border: 1px solid var(--basic-outline-color);
	background-color: var(--base-background-elevation-one);
}

.mySpinsListItem > div {
	flex: 1;
}

.mySpinsActionItems {
	display: grid;
	width: fit-content;
	grid-auto-flow: column;
	margin: auto;
	align-items: center;
	justify-content: center;
	flex: 1;
	gap: 8px;
}

.mySpinsItemOverarch {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
}
.mySpinsItemInfoWrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	flex: 1;
	align-items: center;
	gap: 10px;
}

.MuiButton-contained:not(.MuiButton-sizeLarge) {
	line-height: 2em !important;
	height: 2em !important;
}

.MuiInputLabel-root {
	color: var(--sub-text) !important;
}

.MuiInputBase-root:not(.settingsMenu .MuiInputBase-root) {
	color: var(--basic-text-color) !important;
}

.MuiOutlinedInput-notchedOutline:not(.Mui-focused .MuiOutlinedInput-notchedOutline) {
	border-color: var(--basic-outline-color) !important;
}

.MuiPaper-root.MuiDialog-paper {
	background-color: var(--base-background-elevation-one) !important;
	color: var(--basic-text-color) !important;
}

.MuiSwitch-root:not(.settingsMenu .MuiSwitch-root) .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
	background-color: var(--icon-button-color);
}

.MuiSwitch-root:not(.settingsMenu .MuiSwitch-root) .MuiButtonBase-root.MuiSwitch-switchBase:not(.Mui-checked) + .MuiSwitch-track {
	background-color: var(--basic-text-color);
}

.mySpinsImageWrapper {
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	user-select: none;
	margin: 4px 0px;
}
.mySpinsImage {
	object-fit: contain;
	width: 100%;
	max-height: 80px;
	max-width: 100%;
}

.fadeImageWrapper {
	animation: fadeInImageWrapper 400ms;
}
@keyframes fadeInImageWrapper {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/* ZOOM */
.fade-enter {
	opacity: 0;
}
.fade-enter-active {
	opacity: 1;
	transition: opacity 500ms, transform 500ms;
}
.fade-exit {
	opacity: 1;
}
.fade-exit-active {
	opacity: 0;
	transition: opacity 500ms, transform 500ms;
}

/* ZOOM IMAGE */

.fade-out-image {
	animation: fadeOut 200ms;
	-webkit-animation: fadeOut 200ms;
	-moz-animation: fadeOut 200ms;
	-o-animation: fadeOut 200ms;
	-ms-animation: fadeOut 200ms;
}
@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0.8;
	}
}

@-moz-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0.8;
	}
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0.8;
	}
}

@-o-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0.8;
	}
}

@-ms-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0.8;
	}
}

/* LOADING */

.loadingSpinnerWrapper {
	position: absolute;
	z-index: 100;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* TEXT INPUT */

.denseTextFieldWithoutInteraction textarea {
	caret-color: transparent;
}

/* FILE UPLOAD SORTING */

.fileUploadActionButton {
	display: flex;
	flex-direction: column;
	gap: 4px;
	border-radius: 6px;
	align-items: center;
	padding: 4px;
	justify-content: center;
	position: relative;
	box-sizing: border-box;

	background-color: transparent;
	outline: 0;
	border: 0;
	margin: 0;
	cursor: pointer;
	user-select: none;
	vertical-align: middle;
	text-decoration: none;
	color: inherit;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-weight: 400;
	font-size: 0.8rem;
	text-transform: none;
	width: 42px;
	transition: opacity 1s, visibility 1s;
}

.fileUploadActionButton:hover,
.fileUploadActionButton:focus {
	background: var(--icon-button-hover);
}

.fileUploadActionButtonIcon {
	/* padding: 8px; */
	border-radius: var(--default-border-radius);
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.switchWrapper {
	height: 12px;
	width: 24px;
	border-radius: var(--default-border-radius);
	display: flex;
	align-items: center;
	position: relative;
	transition: background-color 200ms linear;
	box-shadow: inset 0px 0px 8px -4px rgb(0, 0, 0, 0.5);
}

.switchBall {
	position: absolute;
	height: 12px;
	width: 12px;
	border-radius: var(--default-border-radius);
	left: 0;
	transition: transform 200ms linear, background-color 200ms linear;
}

.addImagesButton {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	color: var(--hero-color);
	cursor: pointer;
	user-select: none;
}

.addImagesButton > svg {
	font-size: 1.5rem !important;
}
.addImagesButton > .addImagesWords {
	color: var(--hero-color);
	font-size: 1rem;
	transform: translateY(2px);
}

.addImagesButton > .basicActionWords {
	color: var(--basic-text-color);
	font-size: 1rem;
	transform: translateY(2px);
}

.addImagesButton > .continueActionWords {
	color: rgb(0, 131, 0);
	font-size: 1rem;
	transform: translateY(2px);
}

.addImagesButton.addImagesButtonLarge > svg {
	font-size: 3rem !important;
}

.addImagesButton.createContinueButton > svg {
	color: rgb(0, 131, 0);
}

.addImagesButton.basicActionButton > svg {
	color: var(--basic-text-color);
}

.publicSwitchCreate {
}

.addImagesButton:hover {
	filter: brightness(1.1);
	background-color: var(--icon-button-hover);
	border-radius: 6px;
}

.addImagesWords {
	text-align: center;
}

.addImagesButton.addImagesButtonLarge > .addImagesWords {
	font-size: 1.5rem;
}

@media (max-width: 450px) {
	.smallShowCreate {
		display: unset;
	}
	.smallHideCreate {
		display: none;
	}
}
@media (min-width: 451px) {
	.smallShowCreate {
		display: none;
	}
	.smallHideCreate {
		display: unset;
	}
}
@media (max-width: 900px) {
	.smallShow {
		display: unset;
	}
	.smallHide {
		display: none;
	}
}
@media (min-width: 901px) {
	.smallShow {
		display: none;
	}
	.smallHide {
		display: unset;
	}
}
@media (max-width: 1100px) {
	.smallShowEarly {
		display: unset;
	}
	.smallHideEarly {
		display: none;
	}
}
@media (min-width: 1101px) {
	.smallShowEarly {
		display: none;
	}
	.smallHideEarly {
		display: unset;
	}
}

.sortableGrid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 1em 1em;
	margin: 20px 20px;
	width: calc(100% - 40px);
}

@media (min-width: 0px) {
	.sortableGrid {
		grid-template-columns: repeat(1, 1fr);
	}
}

@media (min-width: 250px) {
	.sortableGrid {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (min-width: 750px) {
	.sortableGrid {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media (min-width: 1000px) {
	.sortableGrid {
		grid-template-columns: repeat(4, 1fr);
	}
}

@media (min-width: 1250px) {
	.sortableGrid {
		grid-template-columns: repeat(5, 1fr);
	}
}
@media (min-width: 1500px) {
	.sortableGrid {
		grid-template-columns: repeat(6, 1fr);
	}
}
@media (min-width: 1750px) {
	.sortableGrid {
		grid-template-columns: repeat(7, 1fr);
	}
}
@media (min-width: 2000px) {
	.sortableGrid {
		grid-template-columns: repeat(8, 1fr);
	}
}
@media (min-width: 2500px) {
	.sortableGrid {
		grid-template-columns: repeat(9, 1fr);
	}
}

.sortableImageWrapper {
	position: relative;
	user-select: none;
	border-radius: var(--default-border-radius);
	padding-bottom: 1.2rem;
}

.removeButtonSortableImage {
	position: absolute !important;
	top: 0 !important;
	right: 0 !important;
	animation-duration: 0s;
	animation-delay: 1s;
	animation-name: delayXButtonLoad;
	animation-iteration-count: 1;
	animation-timing-function: linear;
}

.indexSortableImage {
	position: absolute !important;
	top: 0px !important;
	left: 0px !important;
	color: var(--basic-text-color);
	background: rgba(255, 255, 255, 0.8);
	padding: 8px;
	font-size: 1rem;
	width: 0.8em;
	height: 0.8em;
	border-radius: var(--default-border-radius);
	animation-duration: 0s;
	animation-delay: 1s;
	animation-name: delayXButtonLoad;
	animation-iteration-count: 1;
	animation-timing-function: linear;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	user-select: none;
}

@keyframes delayXButtonLoad {
	0% {
		opacity: 0;
		height: 0%;
	}
	100% {
		opacity: 1;
		height: 100%;
	}
}

.draggableImage {
	user-select: none;
	-webkit-user-select: none;
	-webkit-touch-callout: none !important;
	object-fit: contain;
	width: 100%;
	height: fit-content;
	aspect-ratio: 1;
	max-height: 16em;
	opacity: 1;
	transition: opacity 600ms;
	align-items: center;
	justify-content: center;
}

.imgWrapperDrag {
	user-select: none;
	-webkit-user-select: none;
	-webkit-touch-callout: none !important;
	justify-content: center;
	align-items: center;
}

.imgWrapperDrag:hover {
	cursor: grab;
}

.currentlyDraggingOverlay {
	cursor: grabbing;
}
.currentlyDraggingOverlay * {
	pointer-events: none;
}

/* FILE UPLOAD */

.imageDropArea {
	width: 200px;
	height: 100px;
	border-radius: 1rem;
	background: rgb(255, 192, 192);
	border: 2px solid coral;
}

.uploadProgressNumber {
	position: absolute;
	top: 50%;
	font-size: 1em;
	font-weight: 600;
	transform: translateY(-65%);
	text-align: center;
	width: 100%;
}

.uploadProgressWrapper {
	background-color: var(--base-background);
	padding: 40px 40px;
	margin: 20px;
	border-radius: var(--default-border-radius);
	color: var(--basic-text-color);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 16px;
}

.uploadProgressBar {
	height: 8px !important;
	border-radius: 8px;
}

.actionWrapperNoFiles {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.actionWrapperWithFiles {
	position: relative;
	transform: translateY(0%);
	top: 0;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
}

@media (max-width: 600px) {
	.actionWrapperWithFiles {
		gap: 4px;
	}

	.addImagesButton {
		padding: 4px;
	}
}

@media (min-width: 600px) {
	.actionWrapperWithFiles {
		gap: 20px;
	}
	.addImagesButton {
		padding: 20px;
	}
}

/* BUTTONS  */

.customDisabled {
	pointer-events: none;
	opacity: 0.5;
}

.buttonWrapperEnableTooltipOnDisable {
	width: fit-content;
	display: flex;
}

.MuiButton-contained:not([disabled]) {
	background: var(--icon-button-color);
	color: var(--button-text-color);
}

.MuiButton-contained:not([disabled]):hover {
	background: var(--icon-button-color);
	color: var(--button-text-color);
	filter: brightness(0.9);
}

/* TUTORIALS */
.tutorialOverarch {
	position: relative;
	display: flex;
	max-width: 1000px;
	width: 100%;
	margin: auto;
}

.tableOfContents {
	display: flex;
	flex-direction: column;
}

.tutorialImage {
	width: 100%;
	max-width: 100%;
	max-height: 400px;
	object-fit: contain;
}

.tutorialLinkWrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 20px;
	justify-content: space-evenly;
	align-items: stretch;
	width: calc(100% - 40px);
	max-width: 800px;
	margin: auto;
	padding: 20px;
}
.tutorialLinkItem {
	width: 200px;
	padding: 10px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16), 0 1px 4px rgba(0, 0, 0, 0.23);
	text-decoration: none;
	color: var(--basic-text-color);
}

.tutorialLinkItem:hover {
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12), 0 2px 8px rgba(0, 0, 0, 0.2);
	filter: brightness(1.1);
}
.tutorialLinkImage {
	width: 100%;
	height: 100%;
	max-width: 180px;
	max-height: 120px;
	object-fit: contain;
}
.tutorialLinkTitle {
	font-weight: 600;
	margin-top: 8px;
}

@media (max-width: 1000px) {
	.tableOfContents {
		position: relative;
	}
	.tutorialOverarch {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}

@media (min-width: 1000px) {
	.tableOfContents {
		position: sticky;
		left: 0;
		top: 0;
	}
	.tutorialOverarch {
		flex-direction: row-reverse;
		align-items: flex-start;
		justify-content: center;
	}
}

.tutorialContentWrapper {
	max-width: 800px;
	width: calc(100% - 40px);
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 20px;
	gap: 20px;
}
.tutorialContent {
	max-width: 600px;
}

/* INSPIRE */

.inspireUserInfo {
	text-decoration: none;
	color: var(--sub-text);
}
.inspireUserInfo:hover {
	text-decoration: underline;
}

.allSpinsWrapper {
	gap: 20px;
	margin: 20px auto;
	padding: 0px 20px;
	display: flex;
	flex-wrap: wrap;
	width: calc(100% - 40px);
	max-width: 1000px;
	align-items: stretch;
	justify-content: center;
}

@media (max-width: 460px) {
	.allSpinsWrapper {
		flex-direction: column;
	}
	.inspireSpinItemWrapper {
		/* width: 100%; */
		width: calc(100% - 40px);

	}
}

@media (min-width: 461px) {
	.allSpinsWrapper {
		flex-direction: row;
	}
	.inspireSpinItemWrapper {
		width: 160px;
	}
}

.inspireItemAction {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 30;
}

.inspireSpinItemWrapper {
	/* position: relative; */
	border-radius: 20px;
	/* width: calc(100% - 40px); */
	/* width: calc(100% - 40px); */
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.inspireImage {
	position: relative;
	object-fit: contain;
	width: 100%;
	aspect-ratio: 1;
	/* max-height: 80vw; */
    /* margin: auto; */
}

.inspireSpinItemWrapper:hover,
.inspireSpinItemWrapper:focus,
.inspireSpinItemWrapper:focus-visible {
	cursor: pointer;
	outline-offset: 3px;
}

.logoStill {
	display: flex;
}
.logoMoving {
	display: none;
}
.logoOverarch:hover .logoStill {
	display: none;
}
.logoOverarch:hover .logoMoving {
	display: flex;
}

.loadingLogoText {
	font-size: 22px;
	transform: translateY(7px);
	letter-spacing: 1px;
	font-weight: bold;
	color: #fff;
	fill: #fff;
	font-family: Arial, Helvetica, sans-serif;
}
.behindLoadBody {
	opacity: 0.8;
}

.octoLoader .armOne {
	animation: moveOne var(--armAnimationDurationOctoLoader) linear 0s infinite normal forwards;
}
.octoLoader .armTwo {
	animation: moveTwo var(--armAnimationDurationOctoLoader) linear 0s infinite normal forwards;
}
.octoLoader .armThree {
	animation: moveThree var(--armAnimationDurationOctoLoader) linear 0s infinite normal forwards;
}
.octoLoader .armFour {
	animation: moveFour var(--armAnimationDurationOctoLoader) linear 0s infinite normal forwards;
}
.octoLoader .armFive {
	animation: moveFive var(--armAnimationDurationOctoLoader) linear 0s infinite normal forwards;
}
.octoLoader .armSix {
	animation: moveSix var(--armAnimationDurationOctoLoader) linear 0s infinite normal forwards;
}
.octoLoader .armSeven {
	animation: moveSeven var(--armAnimationDurationOctoLoader) linear 0s infinite normal forwards;
}
.octoLoader .armEight {
	animation: moveEight var(--armAnimationDurationOctoLoader) linear 0s infinite normal forwards;
}

@keyframes moveOne {
	0% {
		filter: brightness(1.05);
		transform: translate(0em, 0em);
		r: 12;
	}
	100% {
		transform: translate(-100%, -0.05em);
		r: 11;
	}
}
@keyframes moveTwo {
	0% {
		filter: brightness(1);
		transform: translate(0rem, 0rem);
		r: 11;
	}
	50% {
		transform: translate(-0.07rem, -0.07rem);
		r: 9.5;
	}
	100% {
		filter: brightness(0.95);
		transform: translate(-0.13rem, -0.14rem);
		r: 8;
	}
}
@keyframes moveThree {
	0% {
		filter: brightness(0.95);
		transform: translate(0em, 0em);
		r: 8;
	}
	50% {
		transform: translate(0.04em, -0.09em);
		r: 7;
	}
	100% {
		filter: brightness(0.9);
		transform: translate(0.15em, -0.15em);
		r: 6;
	}
}
@keyframes moveFour {
	0% {
		filter: brightness(0.9);
		transform: translate(0em, 0em);
		r: 6;
	}
	50% {
		transform: translate(0.125em, -0.03em);
		r: 5;
	}
	100% {
		filter: brightness(0.85);
		transform: translate(0.25em, -0.05em);
		r: 4;
	}
}
@keyframes moveFive {
	0% {
		filter: brightness(0.85);
		transform: translate(0em, 0em);
		r: 4;
	}
	50% {
		transform: translate(0.125em, 0.02em);
		r: 5;
	}
	100% {
		filter: brightness(0.9);
		transform: translate(0.25em, 0.05em);
		r: 6;
	}
}
@keyframes moveSix {
	0% {
		filter: brightness(0.9);
		transform: translate(0em, 0em);
		r: 6;
	}
	50% {
		transform: translate(0.11em, 0.06em);
		r: 7;
	}
	100% {
		filter: brightness(0.95);
		transform: translate(0.15em, 0.15em);
		r: 8;
	}
}
@keyframes moveSeven {
	0% {
		filter: brightness(0.95);
		transform: translate(0em, 0em);
		r: 8;
	}
	50% {
		transform: translate(-0.05em, 0.07em);
		r: 9.5;
	}
	100% {
		filter: brightness(1);
		transform: translate(-0.13em, 0.14em);
		r: 11;
	}
}
@keyframes moveEight {
	0% {
		filter: brightness(1);
		transform: translate(0em, 0em);
		r: 11;
	}
	50% {
		transform: translate(-0.145em, 0.042em);
		r: 11.5;
	}
	100% {
		filter: brightness(1.05);
		transform: translate(-0.27em, 0.05em);
		r: 12;
	}
}

/* ================
PRICING PAGE 
================ */

.pricingExtraInfo {
	width: calc(100% - 40px);
	max-width: 400px;
	font-size: 0.86rem;
	display: grid;
	gap: 4px;
	justify-content: center;
	align-items: center;
	margin: 20px auto;
	opacity: 0.9;
}

.pricingCompTable {
	border-collapse: collapse;
	margin: auto;
}

.pricingCompTable th,
.pricingCompTable td {
	border: 1px solid rgba(150, 150, 150, 0.5);
	text-align: center;
	padding: 8px;
	max-width: 200px;
	word-wrap: break-word;
}
.pricingCompTable th:nth-of-type(1) {
	border: none;
}

.pricingCompTable td:not(td:nth-of-type(1)) {
	min-width: 100px;
}

.pricingCompTable td:nth-of-type(1) {
	min-width: 200px;
}

.priceCardWrapper {
	display: grid;
	justify-content: center;
	gap: 20px;
	width: calc(100% - 40px);
	margin: auto;
	padding: 40px 20px;
}

.tierCard {
	border-radius: 20px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	justify-content: flex-start;
}

.price-level-basic {
	border: 2px solid rgba(150, 150, 150, 0.3);
}
.price-level-starter {
	/* background-color: rgba(222, 142, 100, 0.05);
	border: 2px solid rgba(222, 142, 100, 0.25); */
	background-color: rgba(157, 78, 221, 0.015);
	border: 2px solid rgba(157, 78, 221, 0.35);
	box-shadow: rgba(157, 78, 221, 0.05) 0px 0px 8px, rgba(157, 78, 221, 0.08) 0px 0px 8px;
}

.price-level-advanced {
	/* background-color: rgba(157, 78, 221, 0.03);
	border: 2px solid rgba(157, 78, 221, 0.4);
	box-shadow: rgba(157, 78, 221, 0.05) 0px 0px 8px, rgba(157, 78, 221, 0.08) 0px 0px 8px; */

	background-color: rgba(67, 222, 214, 0.08);
	border: 2px solid #43ded6;
	box-shadow: rgba(67, 222, 214, 0.2) 0px 0px 8px, rgba(67, 222, 214, 0.3) 0px 0px 8px;
}
.price-level-premium {
	/* background-color: rgba(67, 222, 214, 0.1);
	border: 2px solid #43ded6;
	box-shadow: rgba(67, 222, 214, 0.2) 0px 0px 8px, rgba(67, 222, 214, 0.3) 0px 0px 8px; */
	background-color: rgba(157, 78, 221, 0.015);
	border: 2px solid rgba(157, 78, 221, 0.35);
	box-shadow: rgba(157, 78, 221, 0.05) 0px 0px 8px, rgba(157, 78, 221, 0.08) 0px 0px 8px;
}

.price-level-enterprise {
	border: 2px solid rgba(150, 150, 150, 0.3);
}

.tierName {
	display: block;
	text-align: center;
	font-size: 1.6rem;
	color: var(--basic-text-color);
}

.tierPrice {
	display: block;
	text-align: center;
	font-size: 1.4rem;
	color: var(--basic-text-color);
	opacity: 0.8;
}

.tierFeatureList {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.pricingFeature {
	display: inline-flex;
	align-items: flex-start;
	/* justify-content: center; */
	gap: 8px;
	line-height: 1.5rem;
}

.additionalViewsNote {
	font-size: 0.8rem;
	opacity: 0.9;
	margin-left: 4px;
}

@media (max-width: 600px) {
	.priceCardWrapper {
		max-width: 300px;

		grid-template-columns: repeat(1, 1fr);
	}
}

@media (min-width: 600px) and (max-width: 1200px) {
	.priceCardWrapper {
		max-width: 600px;

		grid-template-columns: repeat(2, 1fr);
	}
	.enterpriseCard {
		grid-column-start: 1;
		grid-column-end: span 2;
	}
}

@media (min-width: 1200px) {
	.priceCardWrapper {
		max-width: 1200px;

		grid-template-columns: repeat(4, 1fr);
	}

	.enterpriseCard {
		grid-column-start: 2;
		grid-column-end: span 2;
	}
}

.pricingTable {
	border-collapse: collapse;
	letter-spacing: 1px;
	font-size: 0.9rem;
	width: 100%;
	max-width: 1000px;
}

.pricingTable td,
.pricingTable th {
	border: 1px solid rgb(190, 190, 190);
	padding: 10px 10px;
}

.pricingTable td {
	text-align: center;
}

@media (max-width: 799px) {
	.pricingTable[data-selected='basic'] tr td:not(:nth-of-type(1)):not(:nth-of-type(2)),
	.pricingTable[data-selected='starter'] tr td:not(:nth-of-type(1)):not(:nth-of-type(3)),
	.pricingTable[data-selected='advanced'] tr td:not(:nth-of-type(1)):not(:nth-of-type(4)),
	.pricingTable[data-selected='premium'] tr td:not(:nth-of-type(1)):not(:nth-of-type(5)),
	.pricingHeaderRow,
	.getButtonRow {
		display: none;
	}

	.selectedLevelButton {
		border-bottom: none !important;
		border-bottom-left-radius: 0px !important;
		border-bottom-right-radius: 0px !important;
		pointer-events: none !important;
		touch-action: none !important;
	}

	.levelButtons,
	.smallScreenGetButton,
	.currentTierSmallScreen {
		display: inline-flex !important;
	}
}

@media (min-width: 800px) {
	.pricingTable tr td,
	.pricingTable tr th {
		display: table-cell;
	}

	.getButtonRow,
	.pricingHeaderRow {
		display: table-row;
	}

	.levelButtons,
	.smallScreenGetButton,
	.currentTierSmallScreen {
		display: none !important;
	}
}

.priceOverarch {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	width: calc(100% - 40px);
	padding: 20px;
	gap: 20px;
	justify-content: flex-start;
	align-items: center;
	overflow-x: hidden;
}

.priceWrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 20px;
	/* gap: 20px; */
	justify-content: center;
	width: calc(100% - 40px);
	max-width: 1000px;
	justify-content: center;
	align-items: flex-start;
	position: relative;
	height: 100%;
}

.priceWrapperPricingGrid {
	background-color: teal;
	height: calc(100% - 50px);
	min-height: 300px;
	width: 100%;
	display: none;
	grid-template-rows: 0.5fr 0.5fr 2fr 0.5fr;
}
.levelNamesPricingGrid,
.pricesPricingGrid,
.descriptionsPricingGrid,
.actionItemsPricingGrid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: auto;
}

.everyLevelGrid {
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	background-color: rgb(105, 236, 214);
}

.pricesPricingGrid {
	background-color: rgb(240, 122, 148);
}
.descriptionsPricingGrid {
	background-color: rgb(206, 105, 236);
}
.actionItemsPricingGrid {
	background-color: rgb(230, 207, 105);
}

.descriptionsPricingSection {
	display: flex;
	flex-direction: column;
}

.priceGroup {
	padding: 20px;
	flex: 1;
	flex-direction: column;
}

.priceTitleSection {
	font-size: 1.5em;
	font-weight: 500;
	text-align: center;
	color: rgb(65, 65, 65);
}

.priceAmountSection {
	font-size: 1.2em;
	font-weight: 400;
	text-align: center;
	color: var(--hero-color);
}
.priceDescriptionSection {
}
.priceActionSection {
	position: absolute;
	bottom: 0;
}

/* ================
ERROR PAGE 
================ */

.errorPageOverarch {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.errorWrapper {
	position: relative;
	max-width: 800px;
	margin: 20px;
	padding: 20px 32px;
	border-radius: var(--default-border-radius);
	box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.16);
	display: flex;
	flex-direction: column;
}

.errorInnerWrapper {
	display: flex;
	flex-direction: column;
}

.errorTitle {
	margin-bottom: 20px;
	text-align: center;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	font-size: 32px;
}

/* ================
HOME PAGE 
================ */

@keyframes homeBackground {
	0% {
		background-position: 0% 0%;
	}
	50% {
		background-position: 80% 80%;
	}
	100% {
		background-position: 0% 0%;
	}
}

.homeSectionContents {
	width: 100%;
	max-width: 600px;
	display: grid;
	gap: 20px;
	grid-auto-flow: row;
	margin: auto;
}

.spreadWrapper {
	display: grid;
	column-gap: 20px;
	row-gap: 40px;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	max-width: 1200px;
}

@media (max-width: 900px) {
	.spreadWrapper {
		grid-auto-flow: row;
	}
}
@media (min-width: 900px) {
	.spreadWrapper {
		grid-auto-flow: column;
	}
}

.swimmingOctospinLogo {
	opacity: 0.05;
	position: absolute;
	z-index: -10;
	top: 0%;
	left: 0;
	animation-duration: 400s;
	animation-delay: 0s;
	animation-name: octospinSwim;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

.footerWrapper {
	opacity: 0.8;
	font-size: 0.8rem;
	display: inline-block;
	padding: 10px;
	margin: auto;
	text-align: center;
	width: calc(100% - 20px);
}

.introductionWrapper {
	display: grid;
	grid-auto-flow: row;
	align-items: center;
	justify-content: center;
	gap: 40px;
	padding: 20px 20px 40px 20px;
	width: calc(100% - 40px);
}

.homeWrapper {
	/* background: linear-gradient(#457eac 0%, #9191e9 50%, #457eac 100%); */
	background-color: rgba(150, 150, 150, 0.02);
	/* color: #222; */
	position: relative;
	z-index: 2;
	overflow: hidden;
}

.homeWelcome {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 60px 20px 0px 20px;
	/* color: #fff; */
}

.homeContent {
	width: 100%;
	display: grid;
	grid-auto-flow: row;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 20px;
	z-index: 5;
}

@keyframes octospinSwim {
	0% {
		top: 0%;
		left: 100%;
		transform: translate(-50%, 0%);
	}
	25% {
		top: 50%;
		left: 0%;
		transform: translate(-50%, 0%);
	}
	50% {
		top: 100%;
		left: 100%;
		transform: translate(-50%, -100%);
	}
	75% {
		top: 50%;
		left: 0%;
		transform: translate(-50%, 0%);
	}
	100% {
		top: 0%;
		left: 100%;
		transform: translate(-50%, 0%);
	}
}

.homeSectionFitContent {
	width: fit-content !important;
}

.homeIcon {
	opacity: 0.9;
	border: 6px solid rgba(150, 150, 150, 0.4);
	margin: auto;
	padding: 2px;
	width: 42px;
	height: 42px;
	background-color: rgba(255, 255, 255, 1);
	border-radius: 500px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.homeIcon img {
	width: 34px;
	height: 34px;
	margin: auto;
}

.homeSection {
	border-radius: 20px;
	padding: max(4vw, 20px) max(4vw, 12px);
	display: flex;
	margin: auto;
	width: calc(100% - 10vw);
	max-width: 800px;
	border: 2px solid var(--home-accent-bg);
	/* background-color: var(--base-background); */
	/* background-color: rgba(120, 120, 120, 0.07); */
	/* color: #fff; */
}

.welcomeSpin {
	border-radius: 20px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}
@media (max-width: 750px) {
	.welcomeItemWrapper {
		flex: 1;
		width: 100%;
	}
}

@media (min-width: 750px) {
	.welcomeItemWrapper {
		flex: 1;
		min-width: 375px;
		min-height: 300px;
	}
	.welcomeSpin {
		height: 300px !important;
	}
}

@media (max-width: 900px) {
	.welcomeWrapper {
		grid-auto-flow: row;
	}
}
@media (min-width: 900px) {
	.welcomeWrapper {
		grid-auto-flow: column;
	}
}

/* ================
ACCOUNT PAGE 
================ */

.importantBacknendMessage {
	border: 1px solid var(--danger-red);
	border-radius: 20px;
	padding: 16px;
	background-color: var(--danger-red-background);
	border-width: 2px;
	text-align: center;
}

.accountBillingNotification {
	border: 1px solid var(--alert-orange);
	background-color: var(--alert-orange-background);
	border-radius: 10px;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	width: calc(100% - 20px);
	max-width: 600px;
	margin: auto;
}

.redNotice {
	border: 1px solid var(--danger-red);
	background-color: var(--danger-red-background);
	border-width: 2px;
}

.accountUsageLine {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	align-items: center;
	grid-template-rows: auto;
	max-width: 400px;
	width: calc(100% - 20px);
	margin: 0px 10px;
	padding: 8px 0px;
}

.accountUsageLineWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.accountUsageLine:not(:last-of-type) {
	border-bottom: 1px solid grey;
}

.accountUsageLine td:nth-of-type(1) {
	text-align: left;
}
.accountUsageLine td:nth-of-type(2) {
	text-align: right;
}
.accountUsageLine td:nth-of-type(3) {
	text-align: right;
}

.userNameChangeTextField .MuiOutlinedInput-input {
	padding: 8.5px 34px 8.5px 14px;
}

.accountSectionOverarch {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin: 0px 20px;
}

.accountSection {
	border: 1px solid var(--basic-outline-color);
	padding: 20px;
	border-radius: var(--default-border-radius);
}

.accountSectionHeader {
	font-size: 1.25rem;
	font-weight: 600;
	margin-bottom: 16px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 4px;
}

.accountSectionContent {
	font-size: 1rem;
}

.usageGlance {
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	align-items: flex-end;
	justify-content: center;
}

.baseBeta {
	font-size: 0.6rem;
	background-color: var(--base-background);
	color: var(--basic-text-color);
	border: 2px solid #e86826;
	font-weight: 600;
	padding: 0px 4px 1px 5px;
	border-radius: 20px;
	text-align: 'center';
}

.MuiInputAdornment-root p {
	color: var(--sub-text);
}
